import {
  Box,
  Typography,
  FormLabel,
  Link,
  Stack,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import { ApplicationDocumentFragment, UserRole } from '../../generated/graphql';
import { Link as RouterLink } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { format, formatISO } from 'date-fns';
import { ru } from 'date-fns/locale/ru';

const FieldTypography = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <Box>
    <FormLabel component="legend">{label}</FormLabel>
    <Typography>{value}</Typography>
  </Box>
);

const FieldLink = ({ label, url }: { label: string; url: string }) => (
  <Box>
    <FormLabel component="legend">{label}</FormLabel>
    <Link
      component={RouterLink}
      to={url}
      target="_blank"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      Открыть в новой вкладке
      <OpenInNewIcon sx={{ ml: '6px', fontSize: '16px' }} />
    </Link>
  </Box>
);

export const ApplicationDocumentView = ({
  applicationDocument,
}: {
  applicationDocument: ApplicationDocumentFragment;
}) => {
  return (
    <Box
      p={{
        xs: 1,
        md: 2,
      }}
      bgcolor="#f5f5f5"
      minHeight={{
        xs: 'calc(100vh - 56px)',
        sm: 'calc(100vh - 64px)',
      }}
      overflow={{
        y: 'auto',
      }}
    >
      <Grid
        container
        spacing={{
          xs: 1,
          md: 2,
        }}
      >
        <Grid item xs={12} md={6} lg={4}>
          <Card variant="outlined">
            <CardHeader title="Данные заявления" />
            <CardContent sx={{ pt: 0 }}>
              <Stack spacing={2}>
                <FieldTypography
                  label="Уникальный идентификатор"
                  value={applicationDocument.application.id}
                />
                <FieldTypography
                  label="Ф.И.О. студента"
                  value={applicationDocument.application.student.user.fullName}
                />
                <FieldTypography
                  label="Наименование услуги"
                  value={applicationDocument.serviceDocument.service.name}
                />
                <FieldTypography
                  label="Статус"
                  value={applicationDocument.application.stage.name}
                />

                <FieldTypography
                  label="Дата создания"
                  value={format(
                    formatISO(applicationDocument.application.createdAt),
                    'PPpp',
                    { locale: ru },
                  )}
                />
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Card variant="outlined">
            <CardHeader title="Данные документа" />
            <CardContent sx={{ pt: 0 }}>
              <Stack spacing={2}>
                <FieldTypography
                  label="Уникальный идентификатор"
                  value={applicationDocument.id}
                />
                <FieldTypography
                  label="Наименование документа"
                  value={applicationDocument.serviceDocument.name}
                />
                {applicationDocument.referenceNumber && (
                  <FieldTypography
                    label="Исходящий номер"
                    value={applicationDocument.referenceNumber}
                  />
                )}
                <FieldLink
                  label="Оригинальный документ"
                  url={applicationDocument.signedURL}
                />
                {applicationDocument.fileCardUrl ? (
                  <FieldLink
                    label="Карточка электронного документа"
                    url={applicationDocument.fileCardUrl}
                  />
                ) : (
                  <FieldTypography
                    label="Карточка электронного документа"
                    value="Не сформирована"
                  />
                )}
                <FieldTypography
                  label="Дата создания"
                  value={format(
                    formatISO(applicationDocument.createdAt),
                    'PPpp',
                    { locale: ru },
                  )}
                />
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {applicationDocument.signers.map((signer, index) => {
          const signature = applicationDocument.sigexDocument?.signatures.find(
            (signature) => signature.user.iin === signer.iin,
          );

          return (
            <Grid key={signer.iin} item xs={12} md={6} lg={4}>
              <Card variant="outlined">
                <CardHeader
                  title={`Подпись (${index + 1}/${applicationDocument.signers.length})`}
                />
                <CardContent sx={{ pt: 0 }}>
                  <Stack spacing={2}>
                    {signature && (
                      <FieldTypography
                        label="Уникальный идентификатор"
                        value={signature.id}
                      />
                    )}
                    <FieldTypography
                      label="Роль"
                      value={
                        signer.user.role === UserRole.Student
                          ? 'Студент'
                          : 'Сотрудник'
                      }
                    />
                    {signer.user.role === UserRole.Officer && (
                      <FieldTypography
                        label="Должность"
                        value={signer.user.officer!.position.name}
                      />
                    )}
                    <FieldTypography
                      label="Ф.И.О."
                      value={signer.user.fullName}
                    />
                    <FieldTypography label="ИИН" value={signer.iin} />
                    {signature ? (
                      <FieldTypography
                        label="Дата подписания"
                        value={format(formatISO(signature.createdAt), 'PPpp', {
                          locale: ru,
                        })}
                      />
                    ) : (
                      <FieldTypography label="Статус" value="В ожидании" />
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
