import { createBrowserRouter, Navigate } from 'react-router-dom';

import { Home } from '../../pages/home';
import { ApplicationDocument } from '../../pages/application-document';
import { AppLayout } from './AppLayout';

export const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/:applicationDocumentId',
        element: <ApplicationDocument />,
      },
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
]);
