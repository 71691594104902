import MUIAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export const AppBar = () => (
  <Box sx={{ flexGrow: 1 }}>
    <MUIAppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          SQKO Qujat
        </Typography>
      </Toolbar>
    </MUIAppBar>
  </Box>
);
