import { CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { ApplicationDocumentView } from './ApplicationDocumentView';
import {
  Language,
  useGetApplicationDocumentQuery,
} from '../../generated/graphql';

export const ApplicationDocumentQuery = ({
  applicationDocumentId,
}: {
  applicationDocumentId: string;
}) => {
  const { data, loading, error } = useGetApplicationDocumentQuery({
    variables: {
      applicationDocumentId,
      language: Language.Ru,
    },
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (!data?.qujatGetApplicationDocument) {
    return <Navigate to="/" />;
  }

  return (
    <ApplicationDocumentView
      applicationDocument={data.qujatGetApplicationDocument}
    />
  );
};
