import { useParams } from 'react-router-dom';
import { ApplicationDocumentQuery } from './ApplicationDocumentQuery';

export const ApplicationDocumentParams = () => {
  const { applicationDocumentId } = useParams();

  if (!applicationDocumentId) {
    throw new Error('applicationDocumentId not found');
  }

  return (
    <ApplicationDocumentQuery applicationDocumentId={applicationDocumentId} />
  );
};
