import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export enum Actor {
  Manager = 'MANAGER',
  Student = 'STUDENT'
}

export type Admin = {
  __typename?: 'Admin';
  id: Scalars['ID']['output'];
  university: University;
  user: User;
};

export type Application = {
  __typename?: 'Application';
  createdAt: Scalars['DateTime']['output'];
  data: ApplicationData;
  documents: Array<ApplicationDocument>;
  id: Scalars['ID']['output'];
  serialNumber: Scalars['String']['output'];
  service: Service;
  stage: Stage;
  stageId: Scalars['String']['output'];
  student: Student;
  updatedAt: Scalars['DateTime']['output'];
  updates: Array<ApplicationStageUpdate>;
};

export type ApplicationData = {
  __typename?: 'ApplicationData';
  form: FormSnapshot;
  officerFormData?: Maybe<Scalars['JSON']['output']>;
  service: ServiceSnapshot;
  student: StudentSnapshot;
  studentDocuments: Array<StudentDocumentSnapshot>;
  user: UserSnapshot;
};

export type ApplicationDocument = {
  __typename?: 'ApplicationDocument';
  application: Application;
  createdAt: Scalars['DateTime']['output'];
  file: File;
  fileCard?: Maybe<File>;
  fileCardUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  referenceNumber?: Maybe<Scalars['String']['output']>;
  serviceDocument: ServiceDocument;
  serviceDocumentId: Scalars['String']['output'];
  sigexDocument?: Maybe<SigexDocument>;
  signedURL: Scalars['String']['output'];
  signers: Array<ApplicationDocumentUserSigning>;
  student: Student;
  updatedAt: Scalars['DateTime']['output'];
};

export enum ApplicationDocumentSigningState {
  Signed = 'SIGNED',
  Signing = 'SIGNING'
}

export type ApplicationDocumentUserSigning = {
  __typename?: 'ApplicationDocumentUserSigning';
  applicationDocument: ApplicationDocument;
  applicationDocumentId: Scalars['String']['output'];
  iin: Scalars['String']['output'];
  signed: Scalars['Boolean']['output'];
  user: User;
};

export type ApplicationStageUpdate = {
  __typename?: 'ApplicationStageUpdate';
  createdAt: Scalars['DateTime']['output'];
  message: Scalars['String']['output'];
  previousStageId?: Maybe<Scalars['String']['output']>;
  stageId: Scalars['String']['output'];
};

export type Button = {
  __typename?: 'Button';
  color: ButtonColor;
  label: Scalars['String']['output'];
  labels: Array<Label>;
  variant: ButtonVariant;
};

export enum ButtonColor {
  Error = 'ERROR',
  Info = 'INFO',
  Inherit = 'INHERIT',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Success = 'SUCCESS',
  Warning = 'WARNING'
}

export type ButtonInput = {
  color: ButtonColor;
  labels: Array<LabelInput>;
  variant: ButtonVariant;
};

export enum ButtonVariant {
  Contained = 'CONTAINED',
  Outlined = 'OUTLINED',
  Text = 'TEXT'
}

export enum Degree {
  Bachelor = 'BACHELOR',
  Doctorate = 'DOCTORATE',
  Master = 'MASTER'
}

export type Document = {
  __typename?: 'Document';
  category: DocumentCategory;
  inputs: Array<Input>;
  name: Scalars['String']['output'];
  names: Array<Label>;
};

export enum DocumentCategory {
  Achievement = 'ACHIEVEMENT',
  DisabilityCertificate = 'DISABILITY_CERTIFICATE',
  LargeFamilyCertificate = 'LARGE_FAMILY_CERTIFICATE',
  NationalId = 'NATIONAL_ID',
  OrphanageCertificate = 'ORPHANAGE_CERTIFICATE',
  Photo = 'PHOTO',
  RepatriateId = 'REPATRIATE_ID'
}

export type Faculty = {
  __typename?: 'Faculty';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  names: Array<Label>;
};

export type File = {
  __typename?: 'File';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type FileInput = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type FormSnapshot = {
  __typename?: 'FormSnapshot';
  data: Scalars['JSON']['output'];
  verification?: Maybe<Verification>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type IdName = {
  __typename?: 'IdName';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  names: Array<Label>;
};

export type IdNameInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type Input = {
  __typename?: 'Input';
  label: Scalars['String']['output'];
  labels: Array<Label>;
  name: Scalars['String']['output'];
  params: InputParams;
  position: Scalars['Int']['output'];
  rules: InputRules;
  type: InputType;
};

export type InputOption = {
  __typename?: 'InputOption';
  label: Scalars['String']['output'];
  labels: Array<Label>;
  value: Scalars['String']['output'];
};

export type InputParams = {
  __typename?: 'InputParams';
  defaultValue?: Maybe<Scalars['JSON']['output']>;
  maxDate?: Maybe<Scalars['String']['output']>;
  minDate?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<InputOption>>;
  renderIf?: Maybe<Array<InputRenderIf>>;
  renderIfPresent?: Maybe<Scalars['String']['output']>;
  resetOnChange?: Maybe<Array<InputResetOnChange>>;
};

export type InputRenderIf = {
  __typename?: 'InputRenderIf';
  name: Scalars['String']['output'];
  value: Scalars['JSON']['output'];
};

export type InputResetOnChange = {
  __typename?: 'InputResetOnChange';
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['JSON']['output']>;
};

export type InputRuleBoolean = {
  __typename?: 'InputRuleBoolean';
  message: Scalars['String']['output'];
  messages: Array<Label>;
  value: Scalars['Boolean']['output'];
};

export type InputRuleNumber = {
  __typename?: 'InputRuleNumber';
  message: Scalars['String']['output'];
  messages: Array<Label>;
  value: Scalars['Int']['output'];
};

export type InputRuleRegExp = {
  __typename?: 'InputRuleRegExp';
  message: Scalars['String']['output'];
  messages: Array<Label>;
  value: Scalars['String']['output'];
};

export type InputRules = {
  __typename?: 'InputRules';
  max?: Maybe<InputRuleNumber>;
  maxLength?: Maybe<InputRuleNumber>;
  min?: Maybe<InputRuleNumber>;
  minLength?: Maybe<InputRuleNumber>;
  pattern?: Maybe<InputRuleRegExp>;
  required?: Maybe<InputRuleBoolean>;
};

export enum InputType {
  Checkbox = 'Checkbox',
  Date = 'Date',
  Document = 'Document',
  Faculty = 'Faculty',
  File = 'File',
  Number = 'Number',
  Program = 'Program',
  Radio = 'Radio',
  Region = 'Region',
  Select = 'Select',
  Speciality = 'Speciality',
  Text = 'Text',
  Textarea = 'Textarea',
  University = 'University'
}

export type Label = {
  __typename?: 'Label';
  language: Language;
  value: Scalars['String']['output'];
};

export type LabelInput = {
  language: Language;
  value: Scalars['String']['input'];
};

export enum Language {
  Kk = 'KK',
  Ru = 'RU'
}

export enum MasterDegreeSpecialityType {
  Scientific = 'SCIENTIFIC',
  Specialized = 'SPECIALIZED'
}

export type Mutation = {
  __typename?: 'Mutation';
  adminCreatePosition: Position;
  adminCreateService: Service;
  adminCreateServiceDocument: ServiceDocument;
  adminCreateStage: Stage;
  adminCreateStageTransition: StageTransition;
  adminDeleteService: Service;
  adminDeleteStage: Stage;
  adminDeleteStageTransition: StageTransition;
  adminUpdatePosition: Position;
  adminUpdateService: Service;
  adminUpdateServiceDocument: ServiceDocument;
  adminUpdateStage: Stage;
  adminUpdateStagePositions: Array<Stage>;
  adminUpdateStageTransition: StageTransition;
  login: Scalars['String']['output'];
  logout: Scalars['String']['output'];
  officerUpdateApplication: Application;
  officerUpdateApplicationOfficerForm: Scalars['JSON']['output'];
  officerValidateApplicationForm: Verification;
  officerValidateApplicationStudent: Verification;
  officerValidateApplicationStudentDocument: Verification;
  studentCorrectApplicationForm: FormSnapshot;
  studentCorrectStudentDocumentForm: StudentDocument;
  studentCorrectStudentForm: StudentSnapshot;
  studentCreateApplication: Application;
  studentCreateStudent: Student;
  studentCreateStudentDocument: StudentDocument;
  studentLanguageChange: User;
  studentUpdateApplication: Application;
  studentUpdateStudent: Student;
  studentUpdateStudentDocument: StudentDocument;
};


export type MutationAdminCreatePositionArgs = {
  iin: Scalars['String']['input'];
  names: Array<LabelInput>;
};


export type MutationAdminCreateServiceArgs = {
  code: Scalars['String']['input'];
  names: Array<LabelInput>;
  positionIds: Array<Scalars['String']['input']>;
};


export type MutationAdminCreateServiceDocumentArgs = {
  code: Scalars['String']['input'];
  names: Array<LabelInput>;
  positionIds: Array<Scalars['String']['input']>;
  serviceId: Scalars['String']['input'];
  signedByStudent: Scalars['Boolean']['input'];
};


export type MutationAdminCreateStageArgs = {
  names: Array<LabelInput>;
  serviceId: Scalars['String']['input'];
};


export type MutationAdminCreateStageTransitionArgs = {
  actor: Actor;
  alert?: InputMaybe<StageTransitionAlertInput>;
  button: ButtonInput;
  currentStageId: Scalars['String']['input'];
  nextStageId: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  type: StageTransitionType;
  updateMessages: Array<LabelInput>;
};


export type MutationAdminDeleteServiceArgs = {
  id: Scalars['String']['input'];
};


export type MutationAdminDeleteStageArgs = {
  id: Scalars['String']['input'];
};


export type MutationAdminDeleteStageTransitionArgs = {
  currentStageId: Scalars['String']['input'];
  nextStageId: Scalars['String']['input'];
};


export type MutationAdminUpdatePositionArgs = {
  id: Scalars['String']['input'];
  iin: Scalars['String']['input'];
  names: Array<LabelInput>;
};


export type MutationAdminUpdateServiceArgs = {
  code: Scalars['String']['input'];
  id: Scalars['String']['input'];
  names: Array<LabelInput>;
  positionIds: Array<Scalars['String']['input']>;
};


export type MutationAdminUpdateServiceDocumentArgs = {
  code: Scalars['String']['input'];
  id: Scalars['String']['input'];
  names: Array<LabelInput>;
  positionIds: Array<Scalars['String']['input']>;
  signedByStudent: Scalars['Boolean']['input'];
};


export type MutationAdminUpdateStageArgs = {
  action?: InputMaybe<StageActionInput>;
  id: Scalars['String']['input'];
  names: Array<LabelInput>;
};


export type MutationAdminUpdateStagePositionsArgs = {
  data: Array<StagePositionInput>;
};


export type MutationAdminUpdateStageTransitionArgs = {
  actor: Actor;
  alert?: InputMaybe<StageTransitionAlertInput>;
  button: ButtonInput;
  currentStageId: Scalars['String']['input'];
  nextStageId: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  systemInstructions: Array<StageTransitionSystemInstructionInput>;
  type: StageTransitionType;
  updateMessages: Array<LabelInput>;
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationOfficerUpdateApplicationArgs = {
  id: Scalars['String']['input'];
  stageId: Scalars['String']['input'];
};


export type MutationOfficerUpdateApplicationOfficerFormArgs = {
  applicationId: Scalars['String']['input'];
  data: Scalars['JSON']['input'];
};


export type MutationOfficerValidateApplicationFormArgs = {
  applicationId: Scalars['String']['input'];
  errorMessage?: InputMaybe<Scalars['String']['input']>;
};


export type MutationOfficerValidateApplicationStudentArgs = {
  applicationId: Scalars['String']['input'];
  errorMessage?: InputMaybe<Scalars['String']['input']>;
};


export type MutationOfficerValidateApplicationStudentDocumentArgs = {
  applicationId: Scalars['String']['input'];
  documentCategory: DocumentCategory;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
};


export type MutationStudentCorrectApplicationFormArgs = {
  applicationId: Scalars['String']['input'];
  data: Scalars['JSON']['input'];
};


export type MutationStudentCorrectStudentDocumentFormArgs = {
  applicationId: Scalars['String']['input'];
  data: Scalars['JSON']['input'];
  documentCategory: DocumentCategory;
  files: Array<FileInput>;
};


export type MutationStudentCorrectStudentFormArgs = {
  applicationId: Scalars['String']['input'];
  data: StudentDataInput;
};


export type MutationStudentCreateApplicationArgs = {
  data: Scalars['JSON']['input'];
  serviceId: Scalars['String']['input'];
};


export type MutationStudentCreateStudentArgs = {
  data: StudentDataInput;
};


export type MutationStudentCreateStudentDocumentArgs = {
  data: Scalars['JSON']['input'];
  documentCategory: DocumentCategory;
  files: Array<FileInput>;
};


export type MutationStudentLanguageChangeArgs = {
  language: Language;
};


export type MutationStudentUpdateApplicationArgs = {
  id: Scalars['String']['input'];
  stageId: Scalars['String']['input'];
};


export type MutationStudentUpdateStudentArgs = {
  data: StudentDataInput;
};


export type MutationStudentUpdateStudentDocumentArgs = {
  data: Scalars['JSON']['input'];
  documentCategory: DocumentCategory;
  files: Array<FileInput>;
};

export type NodePosition = {
  __typename?: 'NodePosition';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type NodePositionInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type Officer = {
  __typename?: 'Officer';
  id: Scalars['ID']['output'];
  position: Position;
  user: User;
};

export type OfficerSnapshot = {
  __typename?: 'OfficerSnapshot';
  position: Scalars['String']['output'];
  university: IdName;
  user: UserSnapshot;
};

export type Position = {
  __typename?: 'Position';
  id: Scalars['ID']['output'];
  iin: Scalars['String']['output'];
  managingServices: Array<Service>;
  name: Scalars['String']['output'];
  names: Array<Label>;
  officer?: Maybe<Officer>;
  signingServiceDocuments: Array<ServiceDocument>;
  university: University;
};


export type PositionNameArgs = {
  language?: InputMaybe<Language>;
};

export type Program = {
  __typename?: 'Program';
  degree: Degree;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  names: Array<Label>;
};

export type Qr = {
  __typename?: 'Qr';
  dataURL: Scalars['String']['output'];
  eGovBusinessLaunchLink: Scalars['String']['output'];
  eGovMobileLaunchLink: Scalars['String']['output'];
  expireAt: Scalars['DateTime']['output'];
  qrCode: Scalars['String']['output'];
  signURL: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  adminGetCurrentUser?: Maybe<User>;
  adminGetPosition?: Maybe<Position>;
  adminGetPositions: Array<Position>;
  adminGetService: Service;
  adminGetServiceDocument: ServiceDocument;
  adminGetServiceDocuments: Array<ServiceDocument>;
  adminGetServiceStages: Array<Stage>;
  adminGetServices: Array<Service>;
  documentFactoryGetApplication: Application;
  documentFactoryGetServiceDocument: ServiceDocument;
  getQr: Qr;
  getQrSign: Scalars['String']['output'];
  loadQr: Scalars['String']['output'];
  officerGetApplication: Application;
  officerGetApplicationDocument: ApplicationDocument;
  officerGetApplicationDocuments: Array<ApplicationDocument>;
  officerGetApplications: Array<Application>;
  officerGetCurrentUser: User;
  officerQrAuthenticate: Scalars['String']['output'];
  officerSignApplicationDocumentGetQrSign: ApplicationDocument;
  officerSignApplicationDocumentLoadQr: Scalars['String']['output'];
  qujatGetApplicationDocument?: Maybe<ApplicationDocument>;
  studentGetApplication: Application;
  studentGetApplications: Array<Application>;
  studentGetCurrentUser: User;
  studentGetFaculties: Array<Faculty>;
  studentGetPrograms: Array<Program>;
  studentGetService: Service;
  studentGetServices: Array<Service>;
  studentGetSignedUrlsForFileUpload: Array<SignedUrlKey>;
  studentGetSpecialities: Array<Speciality>;
  studentGetStudentDocument: StudentDocument;
  studentGetStudentInputs: Array<Input>;
  studentGetUniversities: Array<University>;
};


export type QueryAdminGetPositionArgs = {
  id: Scalars['String']['input'];
};


export type QueryAdminGetServiceArgs = {
  id: Scalars['String']['input'];
};


export type QueryAdminGetServiceDocumentArgs = {
  id: Scalars['String']['input'];
};


export type QueryAdminGetServiceDocumentsArgs = {
  serviceId: Scalars['String']['input'];
};


export type QueryAdminGetServiceStagesArgs = {
  serviceId: Scalars['String']['input'];
};


export type QueryDocumentFactoryGetApplicationArgs = {
  id: Scalars['String']['input'];
};


export type QueryDocumentFactoryGetServiceDocumentArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetQrSignArgs = {
  nonce: Scalars['String']['input'];
  signURL: Scalars['String']['input'];
};


export type QueryLoadQrArgs = {
  dataURL: Scalars['String']['input'];
};


export type QueryOfficerGetApplicationArgs = {
  id: Scalars['String']['input'];
};


export type QueryOfficerGetApplicationDocumentArgs = {
  applicationDocumentId: Scalars['String']['input'];
  applicationId: Scalars['String']['input'];
};


export type QueryOfficerGetApplicationDocumentsArgs = {
  state: ApplicationDocumentSigningState;
};


export type QueryOfficerQrAuthenticateArgs = {
  nonce: Scalars['String']['input'];
  signURL: Scalars['String']['input'];
};


export type QueryOfficerSignApplicationDocumentGetQrSignArgs = {
  applicationDocumentId: Scalars['String']['input'];
  signURL: Scalars['String']['input'];
};


export type QueryOfficerSignApplicationDocumentLoadQrArgs = {
  applicationDocumentId: Scalars['String']['input'];
  dataURL: Scalars['String']['input'];
};


export type QueryQujatGetApplicationDocumentArgs = {
  applicationDocumentId: Scalars['String']['input'];
};


export type QueryStudentGetApplicationArgs = {
  id: Scalars['String']['input'];
};


export type QueryStudentGetFacultiesArgs = {
  programId: Scalars['String']['input'];
  studyForm?: InputMaybe<StudyForm>;
};


export type QueryStudentGetProgramsArgs = {
  universityId: Scalars['String']['input'];
};


export type QueryStudentGetServiceArgs = {
  id: Scalars['String']['input'];
};


export type QueryStudentGetSignedUrlsForFileUploadArgs = {
  documentCategory: DocumentCategory;
  fileNames: Array<Scalars['String']['input']>;
};


export type QueryStudentGetSpecialitiesArgs = {
  facultyId: Scalars['String']['input'];
  programId: Scalars['String']['input'];
  specialityType?: InputMaybe<MasterDegreeSpecialityType>;
};


export type QueryStudentGetStudentDocumentArgs = {
  id: Scalars['String']['input'];
};


export type QueryStudentGetStudentInputsArgs = {
  universityId: Scalars['String']['input'];
};

export type Service = {
  __typename?: 'Service';
  code: Scalars['String']['output'];
  documents: Array<ServiceDocument>;
  id: Scalars['ID']['output'];
  inputs: Array<Input>;
  isActive: Scalars['Boolean']['output'];
  managingPositions: Array<Position>;
  name: Scalars['String']['output'];
  names: Array<Label>;
  officerInputs: Array<Input>;
  stages: Array<Stage>;
  university: University;
};


export type ServiceNameArgs = {
  language?: InputMaybe<Language>;
};

export type ServiceDocument = {
  __typename?: 'ServiceDocument';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  names: Array<Label>;
  service: Service;
  serviceId: Scalars['String']['output'];
  signedByStudent: Scalars['Boolean']['output'];
  signingPositions: Array<Position>;
};


export type ServiceDocumentNameArgs = {
  language?: InputMaybe<Language>;
};

export type ServiceSnapshot = {
  __typename?: 'ServiceSnapshot';
  name: Scalars['String']['output'];
  names: Array<Label>;
};

export type SigexDocument = {
  __typename?: 'SigexDocument';
  applicationDocument: ApplicationDocument;
  createdAt: Scalars['DateTime']['output'];
  documentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  signatures: Array<SigexSignature>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SigexSignature = {
  __typename?: 'SigexSignature';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sigexDocument: SigexDocument;
  signId: Scalars['Int']['output'];
  user: User;
};

export type SignedUrlKey = {
  __typename?: 'SignedUrlKey';
  key: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export enum SocialStatus {
  FullFamily = 'FULL_FAMILY',
  Orphan = 'ORPHAN',
  SingleParent = 'SINGLE_PARENT'
}

export type Speciality = {
  __typename?: 'Speciality';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  names: Array<Label>;
};

export type Stage = {
  __typename?: 'Stage';
  action?: Maybe<StageAction>;
  applications: Array<Application>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  names: Array<Label>;
  nextStages: Array<Stage>;
  position: NodePosition;
  previousStages: Array<Stage>;
  service: Service;
  serviceId: Scalars['String']['output'];
  transitions: Array<StageTransition>;
};


export type StageNameArgs = {
  language?: InputMaybe<Language>;
};

export type StageAction = {
  __typename?: 'StageAction';
  actor: Actor;
  button: Button;
  type: StageActionType;
};

export type StageActionInput = {
  actor: Actor;
  button: ButtonInput;
  type: StageActionType;
};

export type StageActionInstruction = {
  __typename?: 'StageActionInstruction';
  description: Scalars['String']['output'];
  role: UserRole;
  severity: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum StageActionType {
  Correction = 'CORRECTION',
  Review = 'REVIEW',
  Signing = 'SIGNING'
}

export type StagePositionInput = {
  id: Scalars['String']['input'];
  position: NodePositionInput;
};

export type StageTransition = {
  __typename?: 'StageTransition';
  actor: Actor;
  alert?: Maybe<StageTransitionAlert>;
  button: Button;
  currentStage: Stage;
  currentStageId: Scalars['String']['output'];
  nextStage: Stage;
  nextStageId: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  systemInstructions: Array<StageTransitionSystemInstruction>;
  type: StageTransitionType;
  updateMessage: Scalars['String']['output'];
  updateMessages: Array<Label>;
};

export type StageTransitionAlert = {
  __typename?: 'StageTransitionAlert';
  button: Button;
  description: Scalars['String']['output'];
  descriptions: Array<Label>;
  title: Scalars['String']['output'];
  titles: Array<Label>;
};

export type StageTransitionAlertInput = {
  button: ButtonInput;
  descriptions: Array<LabelInput>;
  titles: Array<LabelInput>;
};

export type StageTransitionSystemInstruction = {
  __typename?: 'StageTransitionSystemInstruction';
  serviceDocument: ServiceDocument;
  serviceDocumentId: Scalars['String']['output'];
  type: StageTransitionSystemInstructionType;
};

export type StageTransitionSystemInstructionInput = {
  serviceDocumentId: Scalars['String']['input'];
  type: StageTransitionSystemInstructionType;
};

export enum StageTransitionSystemInstructionType {
  CreateDocument = 'CREATE_DOCUMENT'
}

export enum StageTransitionType {
  Alert = 'ALERT',
  Direct = 'DIRECT'
}

export type Student = {
  __typename?: 'Student';
  data: StudentData;
  documents: Array<StudentDocument>;
  id: Scalars['ID']['output'];
  university: University;
  user: User;
};

export type StudentData = {
  __typename?: 'StudentData';
  BACHELOR?: Maybe<StudentDataBachelor>;
  MASTER?: Maybe<StudentDataMaster>;
  courseYear: Scalars['String']['output'];
  faculty: StudentDataFaculty;
  gender: Gender;
  groupName: Scalars['String']['output'];
  program: StudentDataProgram;
  speciality: StudentDataSpeciality;
  studyLanguage: StudyLanguage;
  university: StudentDataUniversity;
};

export type StudentDataBachelor = {
  __typename?: 'StudentDataBachelor';
  studyDuration: StudyDuration;
  studyForm: StudyForm;
};

export type StudentDataBachelorInput = {
  studyDuration: StudyDuration;
  studyForm: StudyForm;
};

export type StudentDataFaculty = {
  __typename?: 'StudentDataFaculty';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  names: Array<Label>;
};

export type StudentDataInput = {
  BACHELOR?: InputMaybe<StudentDataBachelorInput>;
  MASTER?: InputMaybe<StudentDataMasterInput>;
  courseYear: Scalars['String']['input'];
  faculty: IdNameInput;
  gender: Gender;
  groupName: Scalars['String']['input'];
  program: StudentDataProgramInput;
  speciality: IdNameInput;
  studyLanguage: StudyLanguage;
  university: StudentDataUniversityInput;
};

export type StudentDataMaster = {
  __typename?: 'StudentDataMaster';
  specialityType: MasterDegreeSpecialityType;
};

export type StudentDataMasterInput = {
  specialityType: MasterDegreeSpecialityType;
};

export type StudentDataProgram = {
  __typename?: 'StudentDataProgram';
  degree: Degree;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  names: Array<Label>;
};

export type StudentDataProgramInput = {
  degree: Degree;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type StudentDataSpeciality = {
  __typename?: 'StudentDataSpeciality';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  names: Array<Label>;
};

export type StudentDataUniversity = {
  __typename?: 'StudentDataUniversity';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  names: Array<Label>;
};

export type StudentDataUniversityInput = {
  code: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type StudentDocument = {
  __typename?: 'StudentDocument';
  category: DocumentCategory;
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  document: Document;
  files: Array<File>;
  id: Scalars['ID']['output'];
  student: Student;
  updatedAt: Scalars['DateTime']['output'];
};

export type StudentDocumentData = {
  __typename?: 'StudentDocumentData';
  category: DocumentCategory;
  data: Scalars['JSON']['output'];
  files: Array<File>;
  name: Scalars['String']['output'];
};

export type StudentDocumentSnapshot = {
  __typename?: 'StudentDocumentSnapshot';
  data: StudentDocumentData;
  verification?: Maybe<Verification>;
};

export type StudentSnapshot = {
  __typename?: 'StudentSnapshot';
  data: StudentData;
  inputs: Array<Input>;
  verification?: Maybe<Verification>;
};

export enum StudyDuration {
  College = 'COLLEGE',
  HigherEducation = 'HIGHER_EDUCATION',
  HighSchool = 'HIGH_SCHOOL'
}

export enum StudyForm {
  FullTime = 'FULL_TIME',
  FullTimeDlt = 'FULL_TIME_DLT'
}

export enum StudyLanguage {
  En = 'EN',
  Kk = 'KK',
  Ru = 'RU'
}

export type University = {
  __typename?: 'University';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  names: Array<Label>;
  studentInputs: Array<Input>;
};


export type UniversityNameArgs = {
  language?: InputMaybe<Language>;
};

export type User = {
  __typename?: 'User';
  admin?: Maybe<Admin>;
  country: Scalars['String']['output'];
  documents: Array<Document>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  iin: Scalars['String']['output'];
  language: Language;
  lastName: Scalars['String']['output'];
  middleName: Scalars['String']['output'];
  officer?: Maybe<Officer>;
  role: UserRole;
  student?: Maybe<Student>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Officer = 'OFFICER',
  Student = 'STUDENT'
}

export type UserSnapshot = {
  __typename?: 'UserSnapshot';
  country: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  iin: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  middleName: Scalars['String']['output'];
};

export type Verification = {
  __typename?: 'Verification';
  createdAt: Scalars['DateTime']['output'];
  error?: Maybe<VerificationError>;
  officer: OfficerSnapshot;
};

export type VerificationError = {
  __typename?: 'VerificationError';
  corrected: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

export type ServiceDocumentFragment = { __typename?: 'ServiceDocument', id: string, name: string, service: { __typename?: 'Service', id: string, name: string } };

export type ApplicationDocumentUserSigningFragment = { __typename?: 'ApplicationDocumentUserSigning', applicationDocumentId: string, iin: string, signed: boolean, user: { __typename?: 'User', id: string, iin: string, fullName: string, role: UserRole, officer?: { __typename?: 'Officer', id: string, position: { __typename?: 'Position', id: string, iin: string, name: string, university: { __typename?: 'University', id: string, name: string } } } | null } };

export type FileFragment = { __typename?: 'File', key: string, name: string, type: string };

export type SigexSignatureFragment = { __typename?: 'SigexSignature', id: string, signId: number, createdAt: any, user: { __typename?: 'User', id: string, iin: string, fullName: string, role: UserRole, officer?: { __typename?: 'Officer', id: string, position: { __typename?: 'Position', id: string, iin: string, name: string, university: { __typename?: 'University', id: string, name: string } } } | null } };

export type UserFragment = { __typename?: 'User', id: string, iin: string, fullName: string, role: UserRole };

export type UserOfficerFragment = { __typename?: 'User', id: string, iin: string, fullName: string, role: UserRole, officer?: { __typename?: 'Officer', id: string, position: { __typename?: 'Position', id: string, iin: string, name: string, university: { __typename?: 'University', id: string, name: string } } } | null };

export type UniversityFragment = { __typename?: 'University', id: string, name: string };

export type PositionFragment = { __typename?: 'Position', id: string, iin: string, name: string, university: { __typename?: 'University', id: string, name: string } };

export type OfficerFragment = { __typename?: 'Officer', id: string, position: { __typename?: 'Position', id: string, iin: string, name: string, university: { __typename?: 'University', id: string, name: string } } };

export type SigexDocumentFragment = { __typename?: 'SigexDocument', id: string, documentId: string, createdAt: any, updatedAt: any, signatures: Array<{ __typename?: 'SigexSignature', id: string, signId: number, createdAt: any, user: { __typename?: 'User', id: string, iin: string, fullName: string, role: UserRole, officer?: { __typename?: 'Officer', id: string, position: { __typename?: 'Position', id: string, iin: string, name: string, university: { __typename?: 'University', id: string, name: string } } } | null } }> };

export type ApplicationFragment = { __typename?: 'Application', id: string, createdAt: any, stage: { __typename?: 'Stage', name: string }, student: { __typename?: 'Student', id: string, user: { __typename?: 'User', id: string, iin: string, fullName: string, role: UserRole } } };

export type ApplicationDocumentFragment = { __typename?: 'ApplicationDocument', id: string, serviceDocumentId: string, referenceNumber?: string | null, signedURL: string, fileCardUrl?: string | null, createdAt: any, updatedAt: any, serviceDocument: { __typename?: 'ServiceDocument', id: string, name: string, service: { __typename?: 'Service', id: string, name: string } }, signers: Array<{ __typename?: 'ApplicationDocumentUserSigning', applicationDocumentId: string, iin: string, signed: boolean, user: { __typename?: 'User', id: string, iin: string, fullName: string, role: UserRole, officer?: { __typename?: 'Officer', id: string, position: { __typename?: 'Position', id: string, iin: string, name: string, university: { __typename?: 'University', id: string, name: string } } } | null } }>, file: { __typename?: 'File', key: string, name: string, type: string }, sigexDocument?: { __typename?: 'SigexDocument', id: string, documentId: string, createdAt: any, updatedAt: any, signatures: Array<{ __typename?: 'SigexSignature', id: string, signId: number, createdAt: any, user: { __typename?: 'User', id: string, iin: string, fullName: string, role: UserRole, officer?: { __typename?: 'Officer', id: string, position: { __typename?: 'Position', id: string, iin: string, name: string, university: { __typename?: 'University', id: string, name: string } } } | null } }> } | null, application: { __typename?: 'Application', id: string, createdAt: any, stage: { __typename?: 'Stage', name: string }, student: { __typename?: 'Student', id: string, user: { __typename?: 'User', id: string, iin: string, fullName: string, role: UserRole } } } };

export type GetApplicationDocumentQueryVariables = Exact<{
  applicationDocumentId: Scalars['String']['input'];
  language?: InputMaybe<Language>;
}>;


export type GetApplicationDocumentQuery = { __typename?: 'Query', qujatGetApplicationDocument?: { __typename?: 'ApplicationDocument', id: string, serviceDocumentId: string, referenceNumber?: string | null, signedURL: string, fileCardUrl?: string | null, createdAt: any, updatedAt: any, serviceDocument: { __typename?: 'ServiceDocument', id: string, name: string, service: { __typename?: 'Service', id: string, name: string } }, signers: Array<{ __typename?: 'ApplicationDocumentUserSigning', applicationDocumentId: string, iin: string, signed: boolean, user: { __typename?: 'User', id: string, iin: string, fullName: string, role: UserRole, officer?: { __typename?: 'Officer', id: string, position: { __typename?: 'Position', id: string, iin: string, name: string, university: { __typename?: 'University', id: string, name: string } } } | null } }>, file: { __typename?: 'File', key: string, name: string, type: string }, sigexDocument?: { __typename?: 'SigexDocument', id: string, documentId: string, createdAt: any, updatedAt: any, signatures: Array<{ __typename?: 'SigexSignature', id: string, signId: number, createdAt: any, user: { __typename?: 'User', id: string, iin: string, fullName: string, role: UserRole, officer?: { __typename?: 'Officer', id: string, position: { __typename?: 'Position', id: string, iin: string, name: string, university: { __typename?: 'University', id: string, name: string } } } | null } }> } | null, application: { __typename?: 'Application', id: string, createdAt: any, stage: { __typename?: 'Stage', name: string }, student: { __typename?: 'Student', id: string, user: { __typename?: 'User', id: string, iin: string, fullName: string, role: UserRole } } } } | null };

export const ServiceDocumentFragmentDoc = gql`
    fragment ServiceDocument on ServiceDocument {
  id
  name(language: $language)
  service {
    id
    name(language: $language)
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  iin
  fullName
  role
}
    `;
export const UniversityFragmentDoc = gql`
    fragment University on University {
  id
  name(language: $language)
}
    `;
export const PositionFragmentDoc = gql`
    fragment Position on Position {
  id
  iin
  name(language: $language)
  university {
    ...University
  }
}
    ${UniversityFragmentDoc}`;
export const OfficerFragmentDoc = gql`
    fragment Officer on Officer {
  id
  position {
    ...Position
  }
}
    ${PositionFragmentDoc}`;
export const UserOfficerFragmentDoc = gql`
    fragment UserOfficer on User {
  ...User
  officer {
    ...Officer
  }
}
    ${UserFragmentDoc}
${OfficerFragmentDoc}`;
export const ApplicationDocumentUserSigningFragmentDoc = gql`
    fragment ApplicationDocumentUserSigning on ApplicationDocumentUserSigning {
  applicationDocumentId
  iin
  user {
    ...UserOfficer
  }
  signed
}
    ${UserOfficerFragmentDoc}`;
export const FileFragmentDoc = gql`
    fragment File on File {
  key
  name
  type
}
    `;
export const SigexSignatureFragmentDoc = gql`
    fragment SigexSignature on SigexSignature {
  id
  signId
  user {
    ...UserOfficer
  }
  createdAt
}
    ${UserOfficerFragmentDoc}`;
export const SigexDocumentFragmentDoc = gql`
    fragment SigexDocument on SigexDocument {
  id
  documentId
  signatures {
    ...SigexSignature
  }
  createdAt
  updatedAt
}
    ${SigexSignatureFragmentDoc}`;
export const ApplicationFragmentDoc = gql`
    fragment Application on Application {
  id
  stage {
    name(language: $language)
  }
  student {
    id
    user {
      ...User
    }
  }
  createdAt
}
    ${UserFragmentDoc}`;
export const ApplicationDocumentFragmentDoc = gql`
    fragment ApplicationDocument on ApplicationDocument {
  id
  serviceDocumentId
  serviceDocument {
    ...ServiceDocument
  }
  referenceNumber
  signers {
    ...ApplicationDocumentUserSigning
  }
  signedURL
  fileCardUrl
  file {
    ...File
  }
  sigexDocument {
    ...SigexDocument
  }
  application {
    ...Application
  }
  createdAt
  updatedAt
}
    ${ServiceDocumentFragmentDoc}
${ApplicationDocumentUserSigningFragmentDoc}
${FileFragmentDoc}
${SigexDocumentFragmentDoc}
${ApplicationFragmentDoc}`;
export const GetApplicationDocumentDocument = gql`
    query GetApplicationDocument($applicationDocumentId: String!, $language: Language) {
  qujatGetApplicationDocument(applicationDocumentId: $applicationDocumentId) {
    ...ApplicationDocument
  }
}
    ${ApplicationDocumentFragmentDoc}`;

/**
 * __useGetApplicationDocumentQuery__
 *
 * To run a query within a React component, call `useGetApplicationDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationDocumentQuery({
 *   variables: {
 *      applicationDocumentId: // value for 'applicationDocumentId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetApplicationDocumentQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationDocumentQuery, GetApplicationDocumentQueryVariables> & ({ variables: GetApplicationDocumentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationDocumentQuery, GetApplicationDocumentQueryVariables>(GetApplicationDocumentDocument, options);
      }
export function useGetApplicationDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationDocumentQuery, GetApplicationDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationDocumentQuery, GetApplicationDocumentQueryVariables>(GetApplicationDocumentDocument, options);
        }
export function useGetApplicationDocumentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetApplicationDocumentQuery, GetApplicationDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetApplicationDocumentQuery, GetApplicationDocumentQueryVariables>(GetApplicationDocumentDocument, options);
        }
export type GetApplicationDocumentQueryHookResult = ReturnType<typeof useGetApplicationDocumentQuery>;
export type GetApplicationDocumentLazyQueryHookResult = ReturnType<typeof useGetApplicationDocumentLazyQuery>;
export type GetApplicationDocumentSuspenseQueryHookResult = ReturnType<typeof useGetApplicationDocumentSuspenseQuery>;
export type GetApplicationDocumentQueryResult = Apollo.QueryResult<GetApplicationDocumentQuery, GetApplicationDocumentQueryVariables>;