import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { AppBar } from '../../components/AppBar';

export const AppLayout = () => (
  <Box>
    <AppBar />
    <Outlet />
  </Box>
);
