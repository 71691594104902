import { CssBaseline } from '@mui/material';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { ApolloProvider } from '@apollo/client';
import client from '../../config/apollo/client';
import { RouterProvider } from 'react-router-dom';
import { router } from '../../config/router';

export const App = () => (
  <>
    <CssBaseline />
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </>
);
